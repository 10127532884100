import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";

const ExpandDialog = ({ expandedContent, setExpandedContent }) => {
  const { name, description } = expandedContent || {};
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (expandedContent) setIsDialogOpen(true);
  }, [expandedContent]);

  if (!name || !description) return null;

  return (
    <Dialog open={isDialogOpen} onClose={() => setExpandedContent(null)}>
      <DialogTitle fontSize="1.375rem" fontWeight={600}>
        {name}
      </DialogTitle>
      <DialogContent sx={{ minWidth: 300, maxWidth: 500 }}>
        <DialogContentText
          fontSize={14}
          mt={2}
          letterSpacing={0.25}
          fontWeight={500}
        >
          {description}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ExpandDialog;
