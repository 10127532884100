import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import {
  Box,
  Button,
  IconButton,
  Popover as MuiPopover,
  SvgIcon as MuiSvgIcon,
  SvgIconProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ModulesIcon } from "../../assets/icons";
import { urlConfig } from "../../config";

const { hrUrl, payrollUrl, mySpaceUrl, adminUrl } = urlConfig;

const Applications = styled(Box)<{
  isSecond?: boolean;
  has_second_modules?: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background: #fafafa;
  padding: 10px;
  border-radius: ${(props) =>
    props.isSecond
      ? "0 0 16px 16px"
      : props.has_second_modules
      ? "16px 16px 0 0"
      : "16px"};
  margin-top: ${(props) => (props.isSecond ? "10px" : "0")};
`;

const ApplicationItem = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  padding: 20px 10px;
  border-radius: 8px;
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 320px;
    max-height: 450px;
    margin-top: 15px;
    margin-right: 20px;
    box-shadow: ${(props) => props.theme.shadows[2]};
    border-radius: 16px;
    overflow-y: auto;
    background-color: #f3f5fc;
    padding: 16px;
  }
`;

const ViewMoreButton = styled(Button)`
  width: 100%;
  border-radius: 8px;
  padding: 12px 0;
  background: #fafafa;
  margin-top: 10px;
  &:hover {
    background: #fff;
  }
`;

const SvgIcon = styled(MuiSvgIcon)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const modules = [
  { title: "MySpace", url: mySpaceUrl, icon: PersonIcon },
  { title: "Admin", url: adminUrl, icon: AdminPanelSettingsIcon },
  { title: "Payroll", url: payrollUrl, icon: AttachMoneyIcon },
  { title: "HR", url: hrUrl, icon: PeopleIcon },
];

interface ApplicationProps {
  Icon: React.ComponentType<SvgIconProps>;
  handleClose: () => void;
  title: string;
  url: string;
}

const Application: React.FC<ApplicationProps> = ({
  Icon,
  handleClose,
  title,
  url,
}) => (
  <ApplicationItem onClick={handleClose}>
    <Link to={url} target="_blank">
      <SvgIcon fontSize="large">
        <Icon />
      </SvgIcon>
    </Link>
    <Typography
      align="center"
      mt={1}
      fontSize={12}
      fontWeight={600}
      letterSpacing={0.2}
    >
      {title}
    </Typography>
  </ApplicationItem>
);

const NavbarAppsModule: React.FC = () => {
  const ref = useRef<HTMLButtonElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [viewMore, setViewMore] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const handleViewMoreToggle = () => {
    setViewMore(!viewMore);
  };

  const open = Boolean(anchorEl);
  const id = open ? "module-selector" : undefined;

  const firstModules = modules.slice(0, 6);
  const secondModules = viewMore ? modules.slice(6) : modules.slice(6, 9);

  return (
    <>
      <Tooltip title="Apps">
        <IconButton
          color="inherit"
          ref={ref}
          onClick={handleClick}
          size="large"
        >
          <ModulesIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        open={open}
      >
        <Applications
          has_second_modules={secondModules.length > 0 ? true : undefined}
        >
          {firstModules.map(({ title, icon, url }, index) => (
            <Application
              key={index}
              title={title}
              handleClose={handlePopClose}
              Icon={icon}
              url={url}
            />
          ))}
        </Applications>
        {secondModules.length > 0 && (
          <Applications isSecond>
            {secondModules.map(({ title, icon, url }, index) => (
              <Application
                key={index + firstModules.length}
                title={title}
                handleClose={handlePopClose}
                Icon={icon}
                url={url}
              />
            ))}
          </Applications>
        )}
        {modules.length > 9 && (
          <ViewMoreButton onClick={handleViewMoreToggle}>
            <Typography fontSize={14} fontWeight={600}>
              {viewMore ? "View Less" : "View More"}
            </Typography>
          </ViewMoreButton>
        )}
      </Popover>
    </>
  );
};

export default NavbarAppsModule;
