import { Button, Typography } from "@mui/material";

import { AuthService } from "services/auth";

function SignIn() {
  const { doLogin } = AuthService;

  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      fullWidth
      sx={{ py: 2.5, borderRadius: "8px" }}
      onClick={doLogin}
    >
      <Typography variant="body2" fontWeight={600} letterSpacing={1}>
        Sign in
      </Typography>
    </Button>
  );
}

export default SignIn;
