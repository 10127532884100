import {
  Dispatch,
  SetStateAction,
  forwardRef,
  ReactElement,
  Ref,
  useState,
} from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Slide,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import { PlayCircleFilled } from "@mui/icons-material";
import { toast } from "sonner";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

import { ButtonOutlined } from "shared/styled-components/buttons";
import { formatDate } from "shared/utils/date-formatter";
import { formatFileName, getFileType } from "shared/utils/document-formatter";
import { downloadDocument } from "services/organization";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DocumentContainer = styled(Box)`
  background-color: #fff;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  position: relative;
`;

const ActionsContainer = styled(Box)`
  background-color: #fafafa;
  width: 100%;
  height: auto;
  padding: 40px 20px 0;
`;

const DocumentViewer = styled(DocViewer)`
  height: 700px;
  border: none;
  width: 100%;
`;

interface Document {
  id: string;
  file_type: string;
  name: string;
  created_at: string;
  url: string;
}
interface DocumentDialogProps {
  open_document: boolean;
  setOpenDocument: Dispatch<SetStateAction<boolean>>;
  setSelectedDocument: Dispatch<SetStateAction<Document | null>>;
  document: Document;
}

const DocumentDialog = ({
  open_document,
  setOpenDocument,
  setSelectedDocument,
  document,
}: DocumentDialogProps) => {
  const theme = useTheme();
  const [downloading, setDownloading] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpenDocument(false);
    setSelectedDocument(null);
  };

  const handleDocumentDownload = async () => {
    const { id } = document;
    setDownloading(true);
    try {
      const response = await downloadDocument({ document_id: id });
      if (response?.error) {
        toast.error("Something went wrong");
        return;
      }

      window.open(response, "_blank");
    } finally {
      setDownloading(false);
      setOpenDocument(false);
    }
  };

  const { name, created_at, url: uri } = document;

  const document_tray = [{ uri }];
  const file_type = getFileType(name);

  return (
    <Dialog
      open={open_document}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "8px",
          height: "auto",
          width: "100%",
          maxWidth: 1042,
        },
      }}
      TransitionComponent={Transition}
    >
      <Grid container direction={isSmallScreen ? "column" : "row"}>
        <Grid item xs={12} md={9}>
          <DocumentContainer>
            {file_type.toLowerCase() === "pdf" ||
            file_type.toLowerCase() === "unknown" ? (
              <Box
                height={580}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Typography
                  fontSize={24}
                  fontWeight={400}
                  letterSpacing={-1}
                  gutterBottom
                >
                  This document cannot be opened.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ borderRadius: "8px" }}
                  onClick={handleDocumentDownload}
                >
                  Download
                </Button>
              </Box>
            ) : (
              <DocumentViewer
                pluginRenderers={DocViewerRenderers}
                documents={document_tray}
                style={{ height: 560 }}
                config={{
                  header: {
                    overrideComponent: () => (
                      <div style={{ display: "none" }}></div>
                    ),
                  },
                }}
              />
            )}
            <Box display="flex" width="100%" justifyContent="flex-start" mt={4}>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "8px",
                }}
                onClick={() => setOpenDocument(false)}
              >
                Close
              </Button>
            </Box>
          </DocumentContainer>
        </Grid>
        <Grid item xs={12} md={3}>
          <ActionsContainer>
            <Box
              mt={3}
              textAlign="left"
              display="flex"
              flexDirection="column"
              gap={3}
            >
              <Typography fontSize="0.875rem" fontWeight={400}>
                Name: {formatFileName(name)}
              </Typography>
              <Typography fontSize="0.875rem" fontWeight={400}>
                Created: {formatDate(created_at)}
              </Typography>
              <Typography fontSize="0.875rem" fontWeight={400}>
                Type: {getFileType(name)}
              </Typography>
            </Box>
            <Grid container spacing={2} justifyContent="center" mt={4}>
              <Grid item xs={12} sm={12}>
                <Button
                  variant="contained"
                  sx={{ borderRadius: "8px" }}
                  fullWidth
                  onClick={handleDocumentDownload}
                >
                  {downloading ? (
                    <Box display="flex" alignItems="center" gap={1}>
                      Downloading
                      <CircularProgress size="0.8rem" color="inherit" />
                    </Box>
                  ) : (
                    "Download"
                  )}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ButtonOutlined
                  variant="outlined"
                  startIcon={<PlayCircleFilled />}
                  onClick={() =>
                    toast.info("Playback not available for this document")
                  }
                  fullWidth
                >
                  Playback
                </ButtonOutlined>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ButtonOutlined variant="outlined" fullWidth>
                  Read Document
                </ButtonOutlined>
              </Grid>
            </Grid>
          </ActionsContainer>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DocumentDialog;
