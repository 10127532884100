import { createSlice } from "@reduxjs/toolkit";

const payroll_slice = createSlice({
  name: "payroll",
  initialState: [],
  reducers: {
    addPayrollToStore: (state, action) => {
      state = action.payload;
    },
    addOnePayrollToStore: (state, action) => {
      state.payroll.unshift(action.payload);
    },
  },
});

export const { addPayrollToStore, addOnePayrollToStore } =
  payroll_slice.actions;

export default payroll_slice.reducer;
