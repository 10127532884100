import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import { Typography, Box, Paper } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ReactComponent as Logo } from "../../assets/organogram-logo.svg";
import { ViewAllLink } from "shared/styled-components/view-all-link";
import SignInComponent from "./sign-in";
import { officeImages } from "./images";

const LogoWrapper = styled.div`
  position: fixed;
  top: 70px;
  left: 30px;
  z-index: 10;
  svg {
    width: 150px;
  }

  @media (max-width: 768px) {
    top: 50px;
    svg {
      width: 120px;
      filter: invert(1);
    }
  }
`;

const Grid = styled.div`
  --columns: 1fr;

  @media (min-width: 768px) {
    --columns: 1fr 1fr;
  }

  @media (min-width: 1024px) {
    --columns: 1fr 0.8fr;
  }

  display: grid;
  height: 100%;
  grid-template-columns: var(--columns);

  @media (max-width: 768px) {
    & > :first-child {
      display: none;
    }
  }
`;

const GridChild = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:nth-child(2) {
    padding: 0;
    align-items: center;
    position: relative;
    isolation: isolate;
  }
`;

const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  position: absolute;
  inset: 0;
  z-index: -1;
  user-select: none;
  pointer-events: none;
  background-color: #dcdcdc;
`;

const Wrapper = styled(Paper)`
  width: 90%;

  @media (min-width: 500px) {
    width: 350px;
  }

  @media (min-width: 1024px) {
    width: 400px;
  }
  height: max-content;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  border-radius: 8px;
`;

const Link = styled(ViewAllLink)`
  font-weight: 400;
`;
const SignIn = () => {
  const randomImage =
    officeImages[Math.floor(Math.random() * officeImages.length)];
  return (
    <>
      <Helmet title="Sign In" />
      <LogoWrapper>
        <Logo />
      </LogoWrapper>

      <Grid>
        <GridChild>
          <Typography fontSize="2rem" fontWeight={600} gutterBottom>
            A New Enterprise <br /> Awaits.
          </Typography>
          <Typography fontSize="1rem" fontWeight={400}>
            Work smarter. Work better.
          </Typography>
        </GridChild>

        <GridChild>
          <BackgroundImage>
            <LazyLoadImage
              src={randomImage}
              alt="office"
              effect="blur"
              width="100%"
              height="100%"
            />
          </BackgroundImage>

          <Wrapper>
            <Box
              sx={{
                width: 64,
                height: 64,
                backgroundColor: "#DCDCDC",
                borderRadius: "50%",
              }}
            />

            <Box textAlign="center">
              <Typography fontSize="1.25rem" fontWeight={600} mb={1}>
                Welcome to Workspace Payroll
              </Typography>
              <Typography fontSize="0.875rem" fontWeight={400}>
                Sign in to access your dashboard, settings and projects
              </Typography>
            </Box>

            <Box width="100%">
              <SignInComponent />
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Link to="/sign-in">Documentation</Link>
              <Link to="sign-in">Privacy policy</Link>
            </Box>
          </Wrapper>
        </GridChild>
      </Grid>
    </>
  );
};

export default SignIn;
