import { Box } from "@mui/material";
import styled from "styled-components";

const DocumentImageContainer = styled(Box)`
  svg {
    height: 55px;
    width: 55px;
    fill: #bfd1eb;
  }
`;

export default DocumentImageContainer;
