import { Switch, SwitchProps } from "@mui/material";
import styled, { css } from "styled-components";

const CustomSwitch = styled(Switch)<SwitchProps>`
  ${({ theme }) => css`
    width: 44px;
    height: 24px;
    padding: 0;
    & .MuiSwitch-switchBase {
      padding: 0;
      margin: 2;
      transition-duration: 300ms;
      &.Mui-checked {
        transform: translateX(20px);
        color: #fff;
        & + .MuiSwitch-track {
          background-color: #3567b1;
          opacity: 1;
          border: 0;
        }
        &.Mui-disabled + .MuiSwitch-track {
          opacity: 0.5;
        }
      }
      &.Mui-focusVisible .MuiSwitch-thumb {
        color: #3567b1;
        border: 6px solid #fff;
      }
      &.Mui-disabled .MuiSwitch-thumb {
        color: #e9e9ea;
      }
      &.Mui-disabled + .MuiSwitch-track {
        opacity: ${theme.palette.mode === "light" ? 0.7 : 0.3};
      }
    }
    & .MuiSwitch-thumb {
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      margin-top: 2px;
      margin-left: 2px;
    }
    & .MuiSwitch-track {
      border-radius: 12px;
      background-color: ${theme.palette.mode === "light"
        ? "#E9E9EA"
        : "#39393D"};
      opacity: 1;
      transition: ${theme.transitions.create(["background-color"], {
        duration: 500,
      })};
    }
  `}
`;

export default CustomSwitch;
