import { createSlice } from "@reduxjs/toolkit";

const positions_slice = createSlice({
  name: "positions",
  initialState: {
    positions: [],
  },
  reducers: {
    add_positions_to_store: (state, action) => {
      state.positions = action.payload;
    },
    add_one_position_to_store: (state, action) => {
      state.positions.unshift(action.payload);
    },
    remove_one_position_from_store: (state, action) => {
      state.positions = state.positions.filter(
        (position) => position.id !== action.payload.id
      );
    },
    update_one_position_in_store: (state, action) => {
      const positionIndex = state.positions.findIndex(
        (position) => position.id === action.payload.id
      );

      state.positions[positionIndex] = action.payload;
    },
  },
});

export const {
  add_positions_to_store,
  add_one_position_to_store,
  remove_one_position_from_store,
  update_one_position_in_store,
} = positions_slice.actions;

export default positions_slice.reducer;
