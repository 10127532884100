import { IconButton, InputAdornment, InputBase } from "@mui/material";
import Fuse from "fuse.js";
import styled from "styled-components";
import { Search as SearchIcon } from "@mui/icons-material";
import { useMemo, useState, FC, useCallback } from "react";
import debounce from "lodash.debounce";

interface SearchControlProps {
  icon?: typeof SearchIcon;
  ml?: number;
  width?: number;
  backgroundColor?: string;
  border?: string;
  iconColor?: string;
  items: any[];
  keys: string[];
  onResults: (results: any[]) => void;
}

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: #101928;
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
    font-size: 14px;
  }
`;

const Search = styled.div<{
  backgroundColor?: string;
  border?: string;
  ml?: number;
  width?: number;
}>`
  border-radius: 8px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#e6ecf8"};
  border: ${(props) => props.border && `1px solid ${props.border}`};
  position: relative;
  margin-left: ${(props) => props.ml}px;
  height: 44px;
  width: ${(props) => props.width}vw;
  min-width: 250px;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const SearchIconWrapper = styled.div`
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  margin-top: -2px;
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const SearchControl: FC<SearchControlProps> = ({
  icon: Icon,
  ml,
  width,
  backgroundColor,
  border,
  iconColor,
  items,
  keys,
  onResults,
}) => {
  const [query, setQuery] = useState<string>("");

  const fuse = useMemo(() => {
    return new Fuse(items, { keys });
  }, [items, keys]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchWithFuse = useCallback(
    debounce((query: string) => {
      if (query) {
        onResults(fuse.search(query).map((result) => result.item));
      } else {
        onResults(items);
      }
    }, 300),
    [fuse, onResults]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    searchWithFuse(newQuery);
  };

  return (
    <Search
      ml={ml}
      width={width}
      backgroundColor={backgroundColor}
      border={border}
    >
      <SearchIconWrapper>
        <SearchIcon sx={{ color: iconColor && iconColor }} />
      </SearchIconWrapper>
      <Input
        value={query}
        onChange={handleChange}
        placeholder="Search"
        endAdornment={
          Icon && (
            <InputAdornment position="end" sx={{ mr: 2 }}>
              <IconButton sx={{ mt: 1 }}>
                <Icon />
              </IconButton>
            </InputAdornment>
          )
        }
      />
    </Search>
  );
};
