import { Typography } from "@mui/material";
import styled from "styled-components";

const ClampedTypography = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.clamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export default ClampedTypography;
