import { configureStore } from "@reduxjs/toolkit";
import bonuses_ss_Reducer from "./slices/bonus_ss";
import bonusesReducer from "./slices/bonus";
import unitsReducer from "./slices/unit";
import bonusCategoryReducer from "./slices/bonus_category";
import claimsReducer from "./slices/claims";
import claims_ssReducer from "./slices/claims_ss";
import claimTypeReducer from "./slices/claim_type";
import standingOrderCategoryReducer from "./slices/standing_order_category";
import standing_order_ss_Reducer from "./slices/standing_order_ss";
import standingOrderReducer from "./slices/standing_order";
import deduction_ss_Reducer from "./slices/deduction_ss";
import deductionsReducer from "./slices/deduction";
import deductionCategoryReducer from "./slices/deduction_category";
import overtime_ss_Reducer from "./slices/overtime_ss";
import organizationSliceRuducer from "./slices/organization";
import overtimeReducer from "./slices/overtime";
import bucketReducer from "./slices/bucket";
import employeesReducer from "./slices/employees";
import salary_advance_ss_Reducer from "./slices/salary_advance_ss";
import salary_advanceReducer from "./slices/salary_advance";

import wageGarnishmentReducer from "./slices/wage_garnishment";
import wage_garnishment_ss_Reducer from "./slices/wage_garnishment_ss";
import payGroupReducer from "./slices/paygroup";
import payTypeReducer from "./slices/paytype";
import gradeLevelReducer from "./slices/grade_level";
import positionReducer from "./slices/position";
import loanTypeReducer from "./slices/loan_types";
import payrollReducer from "./slices/payroll";
import authReducer from "./slices/auth";
import showBalanceReducer from "./slices/showBalance";
import profileReducer from "./slices/profile";

export const store = configureStore({
  reducer: {
    employees: employeesReducer,
    position: positionReducer,
    auth: authReducer,
    bonus_ss: bonuses_ss_Reducer,
    bonuses: bonusesReducer,
    bonus_category: bonusCategoryReducer,
    claims: claimsReducer,
    units: unitsReducer,
    claims_ss: claims_ssReducer,
    claim_types: claimTypeReducer,
    loan_types: loanTypeReducer,
    deduction_ss: deduction_ss_Reducer,
    deductions: deductionsReducer,
    deduction_category: deductionCategoryReducer,
    organization: organizationSliceRuducer,
    overtime: overtimeReducer,
    salary_advance: salary_advanceReducer,
    salary_advance_ss: salary_advance_ss_Reducer,
    standing_order_category: standingOrderCategoryReducer,
    standing_order_ss: standing_order_ss_Reducer,
    standing_order: standingOrderReducer,
    overtime_ss: overtime_ss_Reducer,
    wage_garnishment: wageGarnishmentReducer,
    wage_garnishment_ss: wage_garnishment_ss_Reducer,
    bucket: bucketReducer,
    groups: payGroupReducer,
    paytype: payTypeReducer,
    grade_levels: gradeLevelReducer,
    payroll: payrollReducer,
    showBalance: showBalanceReducer,
    profile: profileReducer,
  },
});
