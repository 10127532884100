import { createSlice } from "@reduxjs/toolkit";

const loan_type_slice = createSlice({
  name: "loan_types",
  initialState: {
    loan_types: [],
  },
  reducers: {
    add_loan_types_to_store: (state, action) => {
      state.loan_types = action.payload;
    },
    add_one_loan_type_to_store: (state, action) => {
      state.loan_types.unshift(action.payload);
    },
    remove_one_loan_type_from_store: (state, action) => {
      state.loan_types = state.loan_types.filter(
        (loan_type) => loan_type.id !== action.payload.id
      );
    },
    update_one_loan_type_in_store: (state, action) => {
      const loanTypeIndex = state.loan_types.findIndex(
        (loan_type) => loan_type.id === action.payload.id
      );

      state.loan_types[loanTypeIndex] = action.payload;
    },
  },
});

export const {
  add_loan_types_to_store,
  add_one_loan_type_to_store,
  remove_one_loan_type_from_store,
  update_one_loan_type_in_store,
} = loan_type_slice.actions;

export default loan_type_slice.reducer;
