import { createSlice } from "@reduxjs/toolkit";

const salaryad_slice = createSlice({
  name: "salary_advance",
  initialState: {
    salary_advances: [],
  },
  reducers: {
    addSalaryAdvancesToStore: (state, action) => {
      state.salary_advances = action.payload;
    },
    addOneSAlaryAdvanceToStore: (state, action) => {
      state.salary_advances.unshift(action.payload);
    },
    removeOneSalaryAdvanceFromStore: (state, action) => {
      state.salary_advances = state.salary_advances.filter(
        (salary_advance) => salary_advance.id !== action.payload.id
      );
    },
    updateOneSalaryAdvanceInStore: (state, action) => {
      const salaryAdIndex = state.salary_advances.findIndex(
        (salary_advance) => salary_advance.id === action.payload.id
      );
      state.salary_advances[salaryAdIndex] = action.payload;
    },
  },
});

export const {
  addSalaryAdvancesToStore,
  addOneSAlaryAdvanceToStore,
  removeOneSalaryAdvanceFromStore,
  updateOneSalaryAdvanceInStore,
} = salaryad_slice.actions;

export default salaryad_slice.reducer;
