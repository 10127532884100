import { createSlice } from "@reduxjs/toolkit";

const deductions_slice = createSlice({
  name: "deductions",
  initialState: {
    deductions: [],
  },
  reducers: {
    addDeductionsToStore: (state, action) => {
      state.deductions = action.payload;
    },
    addOneDeductionToStore: (state, action) => {
      state.deductions.unshift(action.payload);
    },
    removeOneDeductionFromStore: (state, action) => {
      state.deductions = state.deductions.filter(
        (deduction) => deduction.id !== action.payload.id
      );
    },
    updateOneDeductionInStore: (state, action) => {
      const deductionIndex = state.deductions.findIndex(
        (deduction) => deduction.id === action.payload.id
      );
      state.deductions[deductionIndex] = action.payload;
    },
  },
});

export const {
  addDeductionsToStore,
  addOneDeductionToStore,
  removeOneDeductionFromStore,
  updateOneDeductionInStore,
} = deductions_slice.actions;

export default deductions_slice.reducer;
