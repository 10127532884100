import styled from "styled-components";
import { Paper as MuiPaper, Typography } from "@mui/material";
import { spacing } from "@mui/system";

const Paper = styled(MuiPaper)(spacing);
const NoRecord = () => {
  return (
    <Paper my={4}>
      <Typography align="center" fontSize="0.9rem" fontWeight={500}>
        No content has been added.
      </Typography>
    </Paper>
  );
};

export default NoRecord;
