import {
  Avatar,
  Badge,
  Box,
  Hidden,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import * as React from "react";
import styled from "styled-components/macro";

import { AuthService } from "../../services/auth";

const UserText = styled(Typography)`
  color: #000;
  display: flex;
  align-self: center;
  font-weight: 500;
`;

const UserBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: #4caf50;
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const NavbarUser = () => {
  const { userFullName, doLogout } = AuthService;
  const name = userFullName();
  const [anchorMenu, setAnchorMenu] = React.useState(null);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Hidden lgDown>
        <Box>{!!name && <UserText>{name}</UserText>}</Box>
      </Hidden>
      <Box>
        <UserBadge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          variant="dot"
        >
          {!!name && (
            <Avatar
              sx={{ textTransform: "capitalize", cursor: "pointer" }}
              alt={name}
              src={name}
              onClick={toggleMenu}
            />
          )}

          {!name && <Avatar alt={name} src={name} onClick={toggleMenu} />}
        </UserBadge>
      </Box>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={doLogout}>Sign out</MenuItem>
      </Menu>
    </Box>
  );
};

export default NavbarUser;
