import { createSlice } from "@reduxjs/toolkit";

const claims_ss_slice = createSlice({
  name: "claims_ss",
  initialState: {
    claims: [],
  },
  reducers: {
    add_ss_claims_to_store: (state, action) => {
      state.claims = action.payload;
    },
    add_one_ss_claim_to_store: (state, action) => {
      state.claims.unshift(action.payload);
    },
    remove_one_ss_claim_from_store: (state, action) => {
      state.claims = state.claims.filter(
        (claim) => claim.id !== action.payload.id
      );
    },
    update_one_ss_claim_in_store: (state, action) => {
      const claimIndex = state.claims.findIndex(
        (claim) => claim.id === action.payload.id
      );
      state.claims[claimIndex] = action.payload;
    },
  },
});

export const {
  add_ss_claims_to_store,
  add_one_ss_claim_to_store,
  remove_one_ss_claim_from_store,
  update_one_ss_claim_in_store,
} = claims_ss_slice.actions;

export default claims_ss_slice.reducer;
