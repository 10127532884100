import styled from "styled-components";
import { Button } from "@mui/material";

export const ButtonOutlined = styled(Button)`
  border-radius: 8px;
  color: #464c5e;
  border: 1px solid #d7dae0;
  transition: all 0.3s ease;

  &:hover {
    border: 1px solid #d7dae0;
  }
`;
