// showBalance.js
import { createSlice } from "@reduxjs/toolkit";

const localStorageKey = "showBalance";

function getFromLocalStorage() {
  const storedValue = localStorage.getItem(localStorageKey);
  return storedValue ? JSON.parse(storedValue) : false;
}

function setToLocalStorage(value: boolean) {
  localStorage.setItem(localStorageKey, JSON.stringify(value));
}

const showBalanceSlice = createSlice({
  name: "showBalance",
  initialState: {
    value: getFromLocalStorage(),
  },
  reducers: {
    toggleShowBalance: (state) => {
      const newValue = !state.value;
      setToLocalStorage(newValue);
      state.value = newValue;
    },
  },
});

export const { toggleShowBalance } = showBalanceSlice.actions;

export default showBalanceSlice.reducer;
