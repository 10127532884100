import { createSlice } from "@reduxjs/toolkit";

const overtimes_slice = createSlice({
  name: "overtimes",
  initialState: {
    overtimes: [],
  },
  reducers: {
    addOvertimesToStore: (state, action) => {
      state.overtimes = action.payload;
    },
    addOneOvertimeToStore: (state, action) => {
      state.overtimes.unshift(action.payload);
    },
    removeOneOvertimeFromStore: (state, action) => {
      state.overtimes = state.overtimes.filter(
        (overtime) => overtime.id !== action.payload.id
      );
    },
    updateOneOvertimeInStore: (state, action) => {
      const overtimeIndex = state.overtimes.findIndex(
        (overtime) => overtime.id === action.payload.id
      );
      state.overtimes[overtimeIndex] = action.payload;
    },
  },
});

export const {
  addOvertimesToStore,
  addOneOvertimeToStore,
  removeOneOvertimeFromStore,
  updateOneOvertimeInStore,
} = overtimes_slice.actions;

export default overtimes_slice.reducer;
