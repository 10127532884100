import { createSlice } from "@reduxjs/toolkit";

const standing_order_slice = createSlice({
  name: "standing_order",
  initialState: {
    standing_orders: [],
  },
  reducers: {
    addStandingOrdersToStore: (state, action) => {
      state.standing_orders = action.payload;
    },
    addOneStandingOrderToStore: (state, action) => {
      state.standing_orders.unshift(action.payload);
    },
    removeOneStandingOrderFromStore: (state, action) => {
      state.standing_orders = state.standing_orders.filter(
        (standing_order) =>
          standing_order.employee_id !== action.payload.employee_id
      );
    },
    updateOneStandingOrderInStore: (state, action) => {
      const standingOrderIndex = state.standing_orders.findIndex(
        (standing_order) =>
          standing_order.employee_id === action.payload.employee_id
      );
      state.standing_orders[standingOrderIndex] = action.payload;
    },
  },
});

export const {
  addStandingOrdersToStore,
  addOneStandingOrderToStore,
  removeOneStandingOrderFromStore,
  updateOneStandingOrderInStore,
} = standing_order_slice.actions;

export default standing_order_slice.reducer;
