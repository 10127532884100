import { Typography, Box } from "@mui/material";
import { AddButton } from "shared";

const getArticle = (page_name) => {
  const anPages = ["overtime", "employee"];
  return anPages.includes(page_name.toLowerCase()) ? "an" : "a";
};

const EmptyTableLayout = ({ action, page_name }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
      }}
    >
      <Box spacing={5} mb={5} align="center">
        <Box mb={3}>
          <Typography fontSize="1.25rem" fontWeight={700}>
            No {page_name || "content"} has been added yet
          </Typography>
          <Typography>
            To add {getArticle(page_name)} {page_name || "table"} to this page
            click the button below
          </Typography>
        </Box>
        <AddButton title="Add New" action={action} />
      </Box>
    </Box>
  );
};

export default EmptyTableLayout;
