import { AddOutlined, ExpandMore } from "@mui/icons-material";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import styled from "styled-components";

interface ButtonProps extends MuiButtonProps {
  action?: (...args: any[]) => void;
  fontSize?: number;
  fontWeight?: number;
}

const Button = styled(MuiButton)<ButtonProps>`
  border-radius: 50px;
  padding: 10px 24px;
  font-size: ${(props) => props.fontSize && `${props.fontSize}rem`};
  font-weight: ${(props) => props.fontWeight && `${props.fontWeight}`};
  white-space: nowrap;
`;

export const ButtonOutlined = styled(Button)`
  border-radius: 50px;
  padding: 10px 24px;
  color: #464c5e;
  border: none;
  white-space: nowrap;
  box-shadow: 0 0 0 1px #6f7977;
  transition: all 0.3s ease;
  &:hover {
    border: none;
    box-shadow: 0 0 0 1px #d7dae0;
  }
`;

export const AddButton = ({ action }: ButtonProps) => (
  <Button
    data-testid="add-button"
    variant="contained"
    onClick={action}
    color="primary"
    startIcon={<AddOutlined width={18} height={18} />}
  >
    Add new
  </Button>
);

export const AddButtonOutlined = ({ title, action }: ButtonProps) => (
  <ButtonOutlined
    data-testid="add-button-outlined"
    variant="outlined"
    onClick={action}
    startIcon={<AddOutlined width={18} height={18} />}
  >
    {title}
  </ButtonOutlined>
);

export const MoreOptionsButton = ({ action, title }: ButtonProps) => (
  <ButtonOutlined
    data-testid="more-options-button"
    variant="outlined"
    onClick={action}
    endIcon={<ExpandMore />}
  >
    {title}
  </ButtonOutlined>
);

export const OutlinedButton = ({ children, ...props }: ButtonProps) => (
  <ButtonOutlined variant="outlined" {...props}>
    {children}
  </ButtonOutlined>
);

export const PrimaryButton = ({ children, action, ...props }: ButtonProps) => (
  <Button onClick={action} variant="contained" {...props}>
    {children}
  </Button>
);
