import {
  Breadcrumbs as MuiBreadcrumbs,
  Link as MuiLink,
  Typography as MuiTypography,
} from "@mui/material";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { spacing } from "@mui/system";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Link = styled(MuiLink)`
  font-weight: 700;
  text-decoration: none;
`;

const BreadCrumbs = (props) => {
  const { crumbs, page } = props;
  return (
    <Breadcrumbs aria-label="Breadcrumbs" mt={2}>
      {crumbs.map((crumb, index) => {
        const { to, title } = crumb;
        return (
          <Link key={index} component={NavLink} to={to}>
            {title}
          </Link>
        );
      })}

      <Typography fontWeight={700}>{page}</Typography>
    </Breadcrumbs>
  );
};

export default BreadCrumbs;
