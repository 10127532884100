import { createSlice } from "@reduxjs/toolkit";

const wage_garnishment_ss_slice = createSlice({
  name: "wage_garnishment_ss",
  initialState: {
    wage_garnishments: [],
  },
  reducers: {
    add_ss_wage_garnishments_to_store: (state, action) => {
      state.wage_garnishments = action.payload;
    },
  },
});

export const { add_ss_wage_garnishments_to_store } =
  wage_garnishment_ss_slice.actions;

export default wage_garnishment_ss_slice.reducer;
