import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { Toaster } from "sonner";
import { ThemeProvider } from "styled-components/macro";

import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

import "./i18n";
import createTheme from "./theme";
import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import { authenticated_routes, unauthenticated_routes } from "./routes";
import { AuthService } from "./services/auth";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

const App = () => {
  const { isLoggedIn } = AuthService;
  const authenticated_content = useRoutes(authenticated_routes);
  const unauthenticated_content = useRoutes(unauthenticated_routes);

  const content = isLoggedIn()
    ? authenticated_content
    : unauthenticated_content;

  const { theme } = useTheme();

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Organogram"
        defaultTitle="Organogram Payroll"
      />
      <Provider store={store}>
        <StylesProvider jss={jss}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              fieldMonthPlaceholder: (params) =>
                params.contentType === "letter" ? "MM" : "MMMM",
            }}
          >
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={createTheme(theme)}>
                <ThemeProvider theme={createTheme(theme)}>
                  <Toaster position="top-right" richColors />
                  {content}
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </StylesProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
