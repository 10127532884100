import { createSlice } from "@reduxjs/toolkit";

const grade_level_slice = createSlice({
  name: "grade_levels",
  initialState: {
    grade_levels: [],
  },
  reducers: {
    addGradeLevelsToStore: (state, action) => {
      state.grade_levels = action.payload;
    },
    addOneGradeLevelToStore: (state, action) => {
      state.grade_levels.unshift(action.payload);
    },
    removeOneGradeLevelFromStore: (state, action) => {
      state.grade_levels = state.grade_levels.filter(
        (grade_level) => grade_level.id !== action.payload.id
      );
    },
    editOneGradeLevelInStore: (state, action) => {
      const gradeLevelIndex = state.grade_levels.findIndex(
        (grade_level) => grade_level.id === action.payload.id
      );

      state.grade_levels[gradeLevelIndex] = action.payload;
    },
  },
});
export const {
  addGradeLevelsToStore,
  addOneGradeLevelToStore,
  removeOneGradeLevelFromStore,
  editOneGradeLevelInStore,
} = grade_level_slice.actions;

export default grade_level_slice.reducer;
