import { createSlice } from "@reduxjs/toolkit";

const bonus_category_slice = createSlice({
  name: "bonus_category",
  initialState: {
    category: {},
  },
  reducers: {
    add_categories_to_store: (state, action) => {
      state.category = action.payload;
    },
    add_one_category_to_store: (state, action) => {
      state.category.unshift(action.payload);
    },
    remove_one_category_from_store: (state, action) => {
      state.category = state.category.filter(
        (category) => category.id !== action.payload.id
      );
    },
    update_one_category_in_store: (state, action) => {
      const categoryIndex = state.category.findIndex(
        (category) => category.id === action.payload.id
      );

      state.category[categoryIndex] = action.payload;
    },
  },
});

export const {
  add_categories_to_store,
  add_one_category_to_store,
  remove_one_category_from_store,
  update_one_category_in_store,
} = bonus_category_slice.actions;

export default bonus_category_slice.reducer;
