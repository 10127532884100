import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { AuthService } from "services/auth";

const SessionDialog = () => {
  const { doLogin } = AuthService;

  const handleSignIn = () => {
    doLogin();
  };
  return (
    <Dialog
      open={true}
      sx={{ transition: "all ease 3s" }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle variant="h6" fontWeight={600}>
        Your session has expired.
      </DialogTitle>
      <DialogContent>
        <DialogContentText fontWeight={500}>
          Sign in or refresh browser to continue using application.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSignIn}>Sign in</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionDialog;
