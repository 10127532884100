import { urlConfig } from "../config";
import { apiGet, apiPatch, apiDelete, apiPost, apiPut } from "./_http";

const { apiUrl } = urlConfig;
const apiEndpoint = `${apiUrl}organizations`;
const newApiEndpoint = `${apiUrl}organization`;

const organizationUrl = (id) => {
  return `${apiEndpoint}/${id}`;
};

export const get = async (options = {}) => {
  return await apiGet(`${apiEndpoint}`, { ...options });
};

export const getById = async (id, options = {}) => {
  return await apiGet(organizationUrl(id), { ...options });
};

export const UpdateAddressById = async (id, options) => {
  return await apiPatch(`${newApiEndpoint}/address`, { id, ...options });
};

export const UpdateOrganizationInfo = async (id, options = {}) => {
  return await apiPut(organizationUrl(id), { ...options });
};

export const downloadDocument = async (options = {}) => {
  const { document_id } = options;
  return await apiGet(
    `${apiEndpoint}/documents/download-signed-url/${document_id}`,
    { ...options }
  );
};

// mission
export const updateMission = async (options = {}) => {
  return await apiPatch(`${apiEndpoint}/mission`, { ...options });
};

export const updateVision = async (options = {}) => {
  return await apiPatch(`${apiEndpoint}/vision`, { ...options });
};

// Claim Types

export const getAllClaimTypes = async () => {
  return await apiGet(`${newApiEndpoint}/claim-types`);
};

export const createClaimTypes = async (options) => {
  return await apiPost(`${newApiEndpoint}/claim-types`, options);
};

export const updateClaimTypes = async (claimTypeID, options) => {
  return await apiPut(`${newApiEndpoint}/claim-types/${claimTypeID}`, options);
};

export const deleteClaimTypes = async (claimTypeID) => {
  return await apiDelete(`${newApiEndpoint}/claim-types/${claimTypeID}`);
};

//Standing Order Categories

export const getAllStandingOrders = async () => {
  return await apiGet(`${newApiEndpoint}/standing-order-categories`);
};

export const createStandingOrders = async (options) => {
  return await apiPost(`${newApiEndpoint}/standing-order-categories`, options);
};

export const updateStandingOrders = async (standingOrderID, options) => {
  return await apiPut(
    `${newApiEndpoint}/standing-order-categories/${standingOrderID}`,
    options
  );
};

export const deleteStandingOrders = async (standingOrderID) => {
  return await apiDelete(
    `${newApiEndpoint}/standing-order-categories/${standingOrderID}`
  );
};

//Bonus Category

export const getAllBonusCategory = async () => {
  return await apiGet(`${newApiEndpoint}/bonus-categories`);
};

export const createBonusCategory = async (options) => {
  return await apiPost(`${newApiEndpoint}/bonus-categories`, options);
};

export const updateBonusCategory = async (bonusCategoryID, options) => {
  return await apiPut(
    `${newApiEndpoint}/bonus-categories/${bonusCategoryID}`,
    options
  );
};

export const deleteBonusCategory = async (bonusCategoryID) => {
  return await apiDelete(
    `${newApiEndpoint}/bonus-categories/${bonusCategoryID}`
  );
};

//Deduction Categories

export const getAllDeductionCategory = async () => {
  return await apiGet(`${newApiEndpoint}/deduction-categories`);
};

export const createDeductionCategory = async (options) => {
  return await apiPost(`${newApiEndpoint}/deduction-categories`, options);
};

export const updateDeductionCategory = async (deductionCategoryID, options) => {
  return await apiPut(
    `${newApiEndpoint}/deduction-categories/${deductionCategoryID}`,
    options
  );
};

export const deleteDeductionCategory = async (deductionCategoryID) => {
  return await apiDelete(
    `${newApiEndpoint}/deduction-categories/${deductionCategoryID}`
  );
};

//Group

export const getAllGroups = async () => {
  return await apiGet(`${newApiEndpoint}/groups`);
};

export const createGroup = async (options) => {
  return await apiPost(`${newApiEndpoint}/groups`, options);
};

export const updateGroup = async (groupID, options) => {
  return await apiPut(`${newApiEndpoint}/groups/${groupID}`, options);
};

export const deleteGroup = async (groupID) => {
  return await apiDelete(`${newApiEndpoint}/groups/${groupID}`);
};

// Loan

export const getAllLoans = async () => {
  return await apiGet(`${newApiEndpoint}/loans`);
};

export const createLoan = async (options) => {
  return await apiPost(`${newApiEndpoint}/loans`, options);
};

export const updateLoan = async (loanID, options) => {
  return await apiPut(`${newApiEndpoint}/loans/${loanID}`, options);
};

export const deleteLoan = async (loanID) => {
  return await apiDelete(`${newApiEndpoint}/loans/${loanID}`);
};

// 401K

export const getAll401Ks = async () => {
  return await apiGet(`${newApiEndpoint}/401k`);
};

export const create401K = async (options) => {
  return await apiPost(`${newApiEndpoint}/401k`, options);
};

export const update401K = async (K401_ID, options) => {
  return await apiPut(`${newApiEndpoint}/401k/${K401_ID}`, options);
};

export const delete401K = async (K401_ID) => {
  return await apiDelete(`${newApiEndpoint}/401k/${K401_ID}`);
};

// 401K

export const getAllUnits = async () => {
  return await apiGet(`${newApiEndpoint}/units`);
};

export const createUnit = async (options) => {
  return await apiPost(`${newApiEndpoint}/units`, options);
};

export const updateUnit = async (unitId, options) => {
  return await apiPut(`${newApiEndpoint}/units/${unitId}`, options);
};

export const deleteUnit = async (unitId) => {
  return await apiDelete(`${newApiEndpoint}/units/${unitId}`);
};

// Grade Level

export const getAllGradeLevels = async () => {
  return await apiGet(`${newApiEndpoint}/grade-levels`);
};

export const createGradeLevel = async (options) => {
  return await apiPost(`${newApiEndpoint}/grade-levels`, options);
};

export const updateGradeLevel = async (gradeLevelId, options) => {
  return await apiPut(
    `${newApiEndpoint}/grade-levels/${gradeLevelId}`,
    options
  );
};

export const deleteGradeLevel = async (gradeLevelId) => {
  return await apiDelete(`${newApiEndpoint}/grade-levels/${gradeLevelId}`);
};

// Position

export const getAllPosition = async () => {
  return await apiGet(`${newApiEndpoint}/positions`);
};

export const createPosition = async (options) => {
  return await apiPost(`${newApiEndpoint}/positions`, options);
};

export const updatePositon = async (positionId, options) => {
  return await apiPut(`${newApiEndpoint}/positions/${positionId}`, options);
};

export const deletePosition = async (positionId) => {
  return await apiDelete(`${newApiEndpoint}/positions/${positionId}`);
};
