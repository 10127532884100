import { createSlice } from "@reduxjs/toolkit";

const profile_slice = createSlice({
  name: "profile",
  initialState: {
    profile: {},
  },
  reducers: {
    addProfileToStore: (state, action) => {
      state.profile = action.payload;
    },

    updateProfileInStore: (state, action) => {
      state.profile = action.payload;
    },
  },
});

export const { addProfileToStore, updateProfileInStore } =
  profile_slice.actions;

export default profile_slice.reducer;
