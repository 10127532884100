import AddIcon from "@mui/icons-material/Add";
import { IconButton, Card as MuiCard, Tooltip } from "@mui/material";
import styled from "styled-components";

const Card = styled(MuiCard)`
  border: 1px dashed #d7dae0;
  padding: 24px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => (height ? `${height}px` : "auto")};
  width: ${({ width }) => (width ? `${width}px` : "auto")};
`;

export const AddCard = ({ action, height, width }) => {
  return (
    <Card height={height} width={width} variant="outlined" onClick={action}>
      <Tooltip title="Add">
        <IconButton>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Card>
  );
};
