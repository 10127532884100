import React from "react";
import styled from "styled-components/macro";
import { Snackbar as MuiSnackbar, Alert } from "@mui/material";

const Snackbar = styled(MuiSnackbar)`
  margin-top: 20px;
`;

const ToastNotification = ({ notify, setNotify }) => {
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotify({ ...notify, isOpen: false });
  };
  return (
    <Snackbar
      open={notify.isOpen}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleClose}
    >
      <Alert severity={notify.type} onClose={handleClose}>
        {notify.message}
      </Alert>
    </Snackbar>
  );
};

export default ToastNotification;
