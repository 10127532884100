import React from "react";
import { useSelector } from "react-redux";
import { formatAmount } from "utils/payment";

interface AmountProps {
  children: number | string;
}

const Amount: React.FC<AmountProps> = ({ children }) => {
  const showBalance = useSelector((state: any) => state.showBalance.value);
  children = parseFloat(String(children));
  return showBalance ? formatAmount(children) : "****";
};

export default Amount;
