import styled from "styled-components";
import { Link } from "react-router-dom";

export const ViewAllLink = styled(Link)`
  color: #008eff;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  width: max-content;
`;
