import {
  Article,
  Badge,
  ContentCut,
  CreditCard,
  CurrencyExchange,
  EventRepeat,
  FastForward,
  Groups,
  ManageAccounts,
  MonetizationOn,
  MoreTime,
  PeopleAlt,
  Person,
  PointOfSale,
  Savings,
  Settings,
  Store,
  TrendingUp,
  AddCard,
} from "@mui/icons-material";

const navItems = [
  {
    href: "/profile",
    icon: Person,
    title: "Profile",
    children: [
      {
        href: "/profile/my-profile",
        icon: Person,
        title: "My Profile",
      },
      {
        href: "/profile/overtime",
        icon: MoreTime,
        title: "Overtimes",
      },
      {
        href: "/profile/claims",
        icon: Article,
        title: "Claims",
      },
      {
        href: "/profile/salary-advance",
        icon: FastForward,
        title: "Salary Advance",
      },
      {
        href: "/profile/standing-order",
        icon: MonetizationOn,
        title: "Standing Order",
      },
      {
        href: "/profile/wage-garnishment",
        icon: PointOfSale,
        title: "Wage Garnishment",
      },
      {
        href: "/profile/401K",
        icon: Savings,
        title: "401(k)",
      },
      {
        href: "/profile/loan-application",
        icon: CurrencyExchange,
        title: "Loan Application",
      },
    ],
  },
  {
    href: "/manager",
    icon: ManageAccounts,
    title: "Manager",
    children: [
      {
        href: "/manager/claims",
        icon: Article,
        title: "Claims",
      },
      {
        href: "/manager/overtime",
        icon: MoreTime,
        title: "Overtime",
      },
    ],
  },
  {
    href: "/team",
    icon: Store,
    title: "Teams",
    children: [
      {
        href: "/team/employees",
        title: "Employees",
        icon: PeopleAlt,
      },
      {
        href: "/team/units",
        title: "Units",
        icon: Groups,
      },
      {
        href: "/team/positions",
        title: "Positions",
        icon: Badge,
      },
    ],
  },
  {
    href: "/payroll",
    icon: CreditCard,
    title: "Payroll",
    children: [
      {
        href: "/payroll/pay-schedules",
        icon: EventRepeat,
        title: "Pay Schedules",
      },
      {
        href: "/payroll/claims",
        icon: Article,
        title: "Claims",
      },
      {
        href: "/payroll/overtime",
        icon: MoreTime,
        title: "Overtimes",
      },
      {
        href: "/payroll/bonus",
        icon: AddCard,
        title: "Bonus",
      },
      {
        href: "/payroll/deductions",
        icon: ContentCut,
        title: "Deductions",
      },
      {
        href: "/payroll/salary-advance",
        icon: FastForward,
        title: "Salary Advance",
      },
      {
        href: "/payroll/standing-orders",
        icon: MonetizationOn,
        title: "Standing Orders",
      },
      {
        href: "/payroll/loan-application",
        icon: CurrencyExchange,
        title: "Loan Applications",
      },
      {
        href: "/payroll/401k-subscriptions",
        icon: Savings,
        title: "401(k)",
      },
      {
        href: "/payroll/wage-garnishments",
        icon: PointOfSale,
        title: "Wage Garnishments",
      },
    ],
  },
  {
    href: "/reports",
    icon: TrendingUp,
    title: "Reports",
  },
  {
    href: "/settings",
    icon: Settings,
    title: "Settings",
  },
];

export default navItems;
