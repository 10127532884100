import { useDispatch } from "react-redux";
import { Tooltip } from "@mui/material";
import { toggleShowBalance } from "redux/slices/showBalance";
import { CustomSwitch } from "shared";

const Switch = () => {
  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(toggleShowBalance());
  };

  return (
    <Tooltip title="Toggle Balance">
      <CustomSwitch onClick={handleToggle} />
    </Tooltip>
  );
};

export default Switch;
