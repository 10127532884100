import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { HomeIcon } from "../../assets/icons";

const SideBarContainer = styled.section`
  position: fixed;
  padding-top: 6rem;
  bottom: 0;
  left: 0;
  min-width: 80px;
  max-width: 3rem;
  height: 100%;
  background: #f3f5fc;
  color: #000;
  transition: all 0.2s ease;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    display: none;
  }
`;
const HomeLink = styled(NavLink)`
  background-color: #96b4e3;
  padding: 16px;
  margin-bottom: 30px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 3px 0px #0000004d;

  svg {
    width: 24px;
    height: 24px;
    fill: #464c5e;
  }
`;

const SidebarNavigation = ({ children }) => {
  return (
    <SideBarContainer>
      <HomeLink to="/dashboard">
        <HomeIcon />
      </HomeLink>
      <nav>{children}</nav>
    </SideBarContainer>
  );
};

export default SidebarNavigation;
