import { createSlice } from "@reduxjs/toolkit";

const paytype_slice = createSlice({
  name: "paytype",
  initialState: {
    paytypes: [],
  },
  reducers: {
    addPayTypesToStore: (state, action) => {
      state.paytypes = action.payload;
    },
    addOnePayTypeToStore: (state, action) => {
      state.paytypes.unshift(action.payload);
    },
    removeOnePayTypeFromStore: (state, action) => {
      state.paytypes = state.paytypes.filter(
        (paytype) => paytype.id !== action.payload.id
      );
    },
    updateOnePayTypeInStore: (state, action) => {
      const paytypeIndex = state.paytypes.findIndex(
        (paytype) => paytype.id === action.payload.id
      );
      state.paytypes[paytypeIndex] = action.payload;
    },
  },
});

export const {
  addPayTypesToStore,
  addOnePayTypeToStore,
  removeOnePayTypeFromStore,
  updateOnePayTypeInStore,
} = paytype_slice.actions;

export default paytype_slice.reducer;
