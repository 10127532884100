import { createSlice } from "@reduxjs/toolkit";

const claims_slice = createSlice({
  name: "claims",
  initialState: {
    claims: [],
  },
  reducers: {
    addClaimsToStore: (state, action) => {
      state.claims = action.payload;
    },
    addOneClaimToStore: (state, action) => {
      state.claims.unshift(action.payload);
    },
    removeOneClaimFromStore: (state, action) => {
      state.claims = state.claims.filter(
        (claim) => claim.id !== action.payload.id
      );
    },
    updateOneClaimInStore: (state, action) => {
      const claimIndex = state.claims.findIndex(
        (claim) => claim.id === action.payload.id
      );
      state.claims[claimIndex] = action.payload;
    },
  },
});

export const {
  addClaimsToStore,
  addOneClaimToStore,
  removeOneClaimFromStore,
  updateOneClaimInStore,
} = claims_slice.actions;

export default claims_slice.reducer;
