import { createSlice } from "@reduxjs/toolkit";

const bucket_slice = createSlice({
  name: "bucket",
  initialState: {},
  reducers: {
    setBucket: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { setBucket } = bucket_slice.actions;
export default bucket_slice.reducer;
