import { useState } from "react";

import {
  Box,
  Typography,
  Card as MuiCard,
  IconButton as MuiIconButton,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";

import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";

import MoreVert from "@mui/icons-material/MoreVert";

const Card = styled(MuiCard)`
  padding: 8px 16px;
  border-radius: 8px;
  height: ${({ height }) => (height ? `${height}px` : "auto")};
  width: ${({ minWidth }) => minWidth && `${minWidth}px`};
`;

const ContentBox = styled(Box)`
  * {
    font-size: 14px;
    font-weight: 500;
  }
`;

const IconButton = styled(MuiIconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddressCard = ({
  type,
  address,
  onEditClick,
  minWidth,
  height,
  onAddClick,
}) => {
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);

  const handleActionsMenuClose = () => {
    setActionsAnchorEl(null);
    onEditClick(type);
  };

  const hasAddressData =
    address && Object.values(address).some((value) => value !== "");

  return (
    <Card height={height} style={{ minWidth: minWidth }} variant="outlined">
      <Box display="flex" alignItems="center" gap={2}>
        <PlaceOutlinedIcon />
        <Typography fontWeight={400} fontSize="0.75rem" whiteSpace="nowrap">
          {type}
        </Typography>

        {hasAddressData && (
          <Box ml="auto">
            <Tooltip title="Click to edit">
              <IconButton
                aria-label="options"
                size="small"
                onClick={(event) => setActionsAnchorEl(event.currentTarget)}
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
            <Menu
              data-testid="unit-actions-menu"
              anchorEl={actionsAnchorEl}
              open={Boolean(actionsAnchorEl)}
              onClose={() => setActionsAnchorEl(null)}
            >
              <MenuItem
                data-testid="update-button"
                aria-label="edit button"
                onClick={handleActionsMenuClose}
              >
                Edit
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Box>
      {hasAddressData ? (
        <ContentBox>
          {address.street_number && address.street_name && (
             <Typography mt={2} ml={2}>
              {`${address.street_number}, ${address.street_name}`}
            </Typography>
          )}

          <Box display="flex" alignItems="center">
            {address.neighborhood && (
              <Typography ml={2}>{`${address.neighborhood},`}</Typography>
            )}
            {address.city && (
              <Typography ml={2}>{`${address.city}`}</Typography>
            )}
          </Box>

          {address.state && (
           <Typography ml={2}>{`${address.state}`}</Typography>
          )}
        </ContentBox>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Tooltip title="Add">
            <IconButton aria-label="add" onClick={onAddClick}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Card>
  );
};
