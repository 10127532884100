import { createSlice } from "@reduxjs/toolkit";

const units_slice = createSlice({
  name: "units",
  initialState: {
    units: [],
  },
  reducers: {
    add_units_to_store: (state, action) => {
      state.units = action.payload;
    },
    add_one_unit_to_store: (state, action) => {
      state.units.unshift(action.payload);
    },
    remove_one_unit_from_store: (state, action) => {
      state.units = state.units.filter((unit) => unit.id !== action.payload.id);
    },
    update_one_unit_in_store: (state, action) => {
      const unitIndex = state.units.findIndex(
        (unit) => unit.id === action.payload.id
      );
      state.units[unitIndex] = action.payload;
    },
  },
});

export const {
  add_units_to_store,
  add_one_unit_to_store,
  remove_one_unit_from_store,
  update_one_unit_in_store,
} = units_slice.actions;

export default units_slice.reducer;
