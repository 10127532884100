import styled from "styled-components/macro";
import { Toolbar, Typography } from "@mui/material";

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

export const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 && (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        )}
      </ToolbarTitle>
    </Toolbar>
  );
};
