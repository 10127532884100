import { Box, Tooltip, IconButton, Typography } from "@mui/material";
import { Edit2 } from "react-feather";

const CardHeader = ({ title, onEditClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography fontSize={16} fontWeight={600}>
        {title}
      </Typography>
      <Tooltip title="Click to edit">
        <IconButton
          aria-label="options"
          onClick={onEditClick}
          color="primary"
          size="small"
        >
          <Edit2 size={16} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default CardHeader;
