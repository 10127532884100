import * as React from "react";
import styled, { withTheme } from "styled-components/macro";
import {
  Box,
  Drawer,
  Grid,
  Hidden,
  ListItemButton,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { Logo } from "../../assets/icons";
import NavbarUser from "./current-user";
import NavbarUserSettings from "./general-settings";
import ModuleSelector from "./module-selector.tsx";
import NavbarNotificationsDropdown from "./notifications";
import Switch from "./switch";
import MobileSidebar from "components/sidebar/mobile-sidebar";

const AppBar = styled(MuiAppBar)`
  background: #f3f5fc;
  color: ${(props) => props.theme.header.color};
  border-radius: 0;
`;

const GapBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => (props.theme.breakpoints.down("sm") ? "4px" : "16px")};
`;

const Brand = styled(ListItemButton)`
  font-size: 20px;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: #000;
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-right: ${(props) => props.theme.spacing(6)};
  padding: ${(props) => (props.theme.breakpoints.down("md") ? "10px" : "0")};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  flex-grow: 0;
  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 25px;
    height: 25px;
  }
`;

const BrandIcon = styled(Logo)`
  width: 25px;
  height: 25px;
`;

const Navbar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <React.Fragment>
      <AppBar position="fixed" elevation={3}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  aria-label="Open drawer"
                  size="small"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              <Brand onClick={() => navigate("/dashboard")}>
                <BrandIcon /> <Hidden mdDown>Workspace</Hidden>
              </Brand>
            </Grid>
            <Grid item xs />
            <Box display="flex" gap={isMdUp ? 6 : 3}>
              <GapBox>
                <Switch />
                <ModuleSelector />
                <NavbarUserSettings />
                <NavbarNotificationsDropdown />
              </GapBox>
              <NavbarUser />
            </Box>
          </Grid>
        </Toolbar>
      </AppBar>

      {!isMdUp && (
        <Drawer open={open} onClose={toggleDrawer(false)}>
          <MobileSidebar toggleDrawer={toggleDrawer} />
        </Drawer>
      )}
    </React.Fragment>
  );
};

export default withTheme(Navbar);
