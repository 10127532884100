import { createSlice } from "@reduxjs/toolkit";

const bonus_ss_slice = createSlice({
  name: "bonus_ss",
  initialState: {
    bonuses: [],
  },
  reducers: {
    add_ss_bonuses_to_store: (state, action) => {
      state.bonuses = action.payload;
    },
  },
});

export const { add_ss_bonuses_to_store } = bonus_ss_slice.actions;

export default bonus_ss_slice.reducer;
