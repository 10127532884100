import { createSlice } from "@reduxjs/toolkit";

const standing_order_ss_slice = createSlice({
  name: "standing_order_ss",
  initialState: {
    standing_orders: [],
  },
  reducers: {
    add_ss_standing_orders_to_store: (state, action) => {
      state.standing_orders = action.payload;
    },
  },
});

export const { add_ss_standing_orders_to_store } =
  standing_order_ss_slice.actions;

export default standing_order_ss_slice.reducer;
