import { Box } from "@mui/material";
import styled from "styled-components";

const CardContainer = styled(Box)`
  position: relative;
  width: 100%;
  height: 196px;
  border: 1px solid #dcdcdc;
  padding: 16px;
  gap: 24px;
  border-radius: 8px;
`;

export const DocumentCard = ({ maxWidth, children }) => {
  return <CardContainer maxWidth={maxWidth}>{children}</CardContainer>;
};
