import { createSlice } from "@reduxjs/toolkit";

const bonuses_slice = createSlice({
  name: "bonuses",
  initialState: {
    bonuses: [],
  },
  reducers: {
    addBonusesToStore: (state, action) => {
      state.bonuses = action.payload;
    },
    addOneBonusToStore: (state, action) => {
      state.bonuses.unshift(action.payload);
    },
    removeOneBonusFromStore: (state, action) => {
      state.bonuses = state.bonuses.filter(
        (bonus) => bonus.id !== action.payload.id
      );
    },
    updateOneBonusInStore: (state, action) => {
      const bonusIndex = state.bonuses.findIndex(
        (bonus) => bonus.id === action.payload.id
      );
      state.bonuses[bonusIndex] = action.payload;
    },
  },
});

export const {
  addBonusesToStore,
  addOneBonusToStore,
  removeOneBonusFromStore,
  updateOneBonusInStore,
} = bonuses_slice.actions;

export default bonuses_slice.reducer;
