import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { SettingsIcon } from "../../assets/icons";

function NavbarUserSettings() {
  const navigate = useNavigate();
  return (
    <>
      <Tooltip title="Settings">
        <IconButton
          color="inherit"
          aria-label="settings"
          size="large"
          onClick={() => navigate("/settings")}
        >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default NavbarUserSettings;
