import { createSlice } from "@reduxjs/toolkit";

const wage_garnishment_slice = createSlice({
  name: "wage_garnishment",
  initialState: {
    wage_garnishments: [],
  },
  reducers: {
    addWageGarnishmentsToStore: (state, action) => {
      state.wage_garnishments = action.payload;
    },
    addOneWageGarnishmentToStore: (state, action) => {
      state.wage_garnishments.unshift(action.payload);
    },
    removeOneWageGarnishmentFromStore: (state, action) => {
      state.wage_garnishments = state.wage_garnishments.filter(
        (wage_garnishment) => wage_garnishment.id !== action.payload.id
      );
    },
    updateOneWageGarnishmentFromStore: (state, action) => {
      const salaryAdIndex = state.wage_garnishments.findIndex(
        (wage_garnishment) => wage_garnishment.id === action.payload.id
      );
      state.wage_garnishments[salaryAdIndex] = action.payload;
    },
  },
});

export const {
  addWageGarnishmentsToStore,
  addOneWageGarnishmentToStore,
  removeOneWageGarnishmentFromStore,
  updateOneWageGarnishmentFromStore,
} = wage_garnishment_slice.actions;

export default wage_garnishment_slice.reducer;
