import {
  Dispatch,
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  SetStateAction,
} from "react";
import styled from "styled-components";
import {
  Dialog,
  DialogContent as MuiDialogContent,
  Box,
  IconButton,
  Typography,
  Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

const DialogContent = styled(MuiDialogContent)`
  &::-webkit-scrollbar {
    width: 0;
  }
  padding-left: 8px;
  padding-right: 8px;
`;

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>,
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface PopupProps {
  children: ReactNode;
  openPopup: boolean;
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  cancel?: Boolean;
  title: string;
}

const PopUp = ({
  children,
  openPopup,
  setOpenPopup,
  cancel,
  title,
}: PopupProps) => {
  const handleClose = () => {
    setOpenPopup(false);
  };

  return (
    <Dialog
      open={openPopup}
      onClose={handleClose}
      PaperProps={{
        style: { borderRadius: "10px" },
      }}
      TransitionComponent={Transition}
    >
      {cancel ? (
        <Box display="flex" justifyContent="flex-end" mr={2} mt={2}>
          <IconButton color="default" onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      ) : null}
      <Typography variant="h5" gutterBottom my={3} ml={5.5}>
        {title}
      </Typography>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default PopUp;

