import { Typography } from "@mui/material";
import styled from "styled-components";

const TruncatedTypography = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: max-content;
`;

export default TruncatedTypography;
