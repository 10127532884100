import { createSlice } from "@reduxjs/toolkit";

const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    organization: {},
    address: {},
    bonusCategories: [],
    claimTypes: [],
    standingOrderCategories: [],
    deductionCategories: [],
    mission: "",
    groups: [],
    loans: [],
    K401: [],
    units: [],
    gradeLevels: [],
    positions: [],
    payTypes: [],
  },
  reducers: {
    addOrganizationToStore: (state, action) => {
      state.organization = action.payload;
    },
    updateMissionInStore: (state, action) => {
      state.mission = action.payload;
    },
    updateAddressInStore: (state, action) => {
      state.address = action.payload;
    },
    addAllBonusCategoriesToStore: (state, action) => {
      state.bonusCategories = action.payload;
    },
    updateBonusCategoryInStore: (state, action) => {
      state.bonusCategories = state.bonusCategories.map((bonusCategory) =>
        bonusCategory.id === action.payload.id ? action.payload : bonusCategory
      );
    },
    removeBonusCategoryFromStore: (state, action) => {
      state.bonusCategories = state.bonusCategories.filter(
        (bonusCategory) => bonusCategory.id !== action.payload.id
      );
    },
    addOneBonusCategoryToStore: (state, action) => {
      state.bonusCategories.unshift(action.payload);
    },
    addAllDeductionCategoriesToStore: (state, action) => {
      state.deductionCategories = action.payload;
    },
    addOneDeductionCategoryToStore: (state, action) => {
      state.deductionCategories.unshift(action.payload);
    },
    updateDeductionCategoryInStore: (state, action) => {
      state.deductionCategories = state.deductionCategories.map(
        (deductionCategory) =>
          deductionCategory.id === action.payload.id
            ? action.payload
            : deductionCategory
      );
    },
    removeDeductionCategoryFromStore: (state, action) => {
      state.deductionCategories = state.deductionCategories.filter(
        (deductionCategory) => deductionCategory.id !== action.payload.id
      );
    },
    addAllClaimTypesToStore: (state, action) => {
      state.claimTypes = action.payload;
    },

    updateClaimTypesInStore: (state, action) => {
      state.claimTypes = state.claimTypes.map((claimType) =>
        claimType.id === action.payload.id ? action.payload : claimType
      );
    },
    removeClaimTypesFromStore: (state, action) => {
      state.claimTypes = state.claimTypes.filter(
        (claimType) => claimType.id !== action.payload.id
      );
    },

    addOneClaimTypesToStore: (state, action) => {
      state.claimTypes.unshift(action.payload);
    },

    addAllStandingOrdersToStore: (state, action) => {
      state.standingOrderCategories = action.payload;
    },

    addOneStandingOrderToStore: (state, action) => {
      state.standingOrderCategories.unshift(action.payload);
    },

    updateStandingOrderInStore: (state, action) => {
      state.standingOrderCategories = state.standingOrderCategories.map(
        (standingOrder) =>
          standingOrder.id === action.payload.id
            ? action.payload
            : standingOrder
      );
    },

    removeOneStandingOrderFromStore: (state, action) => {
      state.standingOrderCategories = state.standingOrderCategories.filter(
        (standingOrder) => standingOrder.id !== action.payload.id
      );
    },

    addAllGroupToStore: (state, action) => {
      state.groups = action.payload;
    },

    addOneGroupToStore: (state, action) => {
      state.groups.unshift(action.payload);
    },

    updateGroupInStore: (state, action) => {
      state.groups = state.groups.map((group) =>
        group.id === action.payload.id ? action.payload : group
      );
    },

    removeOneGroupFromStore: (state, action) => {
      state.groups = state.groups.filter(
        (group) => group.id !== action.payload.id
      );
    },

    addAllLoansToStore: (state, action) => {
      state.loans = action.payload;
    },

    addOneLoanToStore: (state, action) => {
      state.loans.unshift(action.payload);
    },

    updateLoanInStore: (state, action) => {
      state.loans = state.loans.map((loan) =>
        loan.id === action.payload.id ? action.payload : loan
      );
    },

    removeOneLoanFromStore: (state, action) => {
      state.loans = state.loans.filter((loan) => loan.id !== action.payload.id);
    },

    addAll401KsToStore: (state, action) => {
      state.K401 = action.payload;
    },

    addOne401KToStore: (state, action) => {
      state.K401.unshift(action.payload);
    },

    update401KInStore: (state, action) => {
      state.K401 = state.K401.map((K401) =>
        K401.id === action.payload.id ? action.payload : K401
      );
    },

    removeOne401KFromStore: (state, action) => {
      state.K401 = state.K401.filter((K401) => K401.id !== action.payload.id);
    },

    addAllUnitsToStore: (state, action) => {
      state.units = action.payload;
    },

    addOneUnitToStore: (state, action) => {
      state.units.unshift(action.payload);
    },

    updateUnitInStore: (state, action) => {
      state.units = state.units.map((unit) =>
        unit.id === action.payload.id ? action.payload : unit
      );
    },

    removeOneUnitFromStore: (state, action) => {
      state.units = state.units.filter((unit) => unit.id !== action.payload.id);
    },

    addAllGradeLevelsToStore: (state, action) => {
      state.gradeLevels = action.payload;
    },

    addOneGradeLevelToStore: (state, action) => {
      state.gradeLevels.unshift(action.payload);
    },

    updateGradeLevelInStore: (state, action) => {
      state.gradeLevels = state.gradeLevels.map((gradeLevel) =>
        gradeLevel.id === action.payload.id ? action.payload : gradeLevel
      );
    },

    removeOneGradeLevelFromStore: (state, action) => {
      state.gradeLevels = state.gradeLevels.filter(
        (gradeLevel) => gradeLevel.id !== action.payload.id
      );
    },

    addAllPositionsToStore: (state, action) => {
      state.positions = action.payload;
    },

    addOnePositionToStore: (state, action) => {
      state.positions.unshift(action.payload);
    },

    updatePositionInStore: (state, action) => {
      state.positions = state.positions.map((position) =>
        position.id === action.payload.id ? action.payload : position
      );
    },

    removeOnePositionFromStore: (state, action) => {
      state.positions = state.positions.filter(
        (position) => position.id !== action.payload.id
      );
    },

    addAllPayTypesToStore: (state, action) => {
      state.payTypes = action.payload;
    },

    addOnePayTypesToStore: (state, action) => {
      state.payTypes.unshift(action.payload);
    },

    updatePayTypesInStore: (state, action) => {
      state.payTypes = state.payTypes.map((payType) =>
        payType.id === action.payload.id ? action.payload : payType
      );
    },

    removeOnePayTypesFromStore: (state, action) => {
      state.payTypes = state.payTypes.filter(
        (payType) => payType.id !== action.payload.id
      );
    },
  },
});

export const {
  addOrganizationToStore,
  addOnboardingStepsToStore,
  addLeaveTypesToStore,
  removeOneLeaveTypeFromStore,
  addExitReasonsToStore,
  addOneExitReasonToStore,
  removeOneExitReasonFromStore,
  updateOneExitReasonInStore,
  addDocumentsToStore,
  addOneDocumentToStore,
  removeOneDocumentFromStore,
  updateMissionInStore,
  updateVisionInStore,
  updateAddressInStore,
  addAllBonusCategoriesToStore,
  addOneBonusCategoryToStore,
  updateBonusCategoryInStore,
  removeBonusCategoryFromStore,
  addAllClaimTypesToStore,
  addOneClaimTypesToStore,
  updateClaimTypesInStore,
  removeClaimTypesFromStore,
  addAllStandingOrdersToStore,
  addOneStandingOrderToStore,
  updateStandingOrderInStore,
  removeOneStandingOrderFromStore,
  addAllDeductionCategoriesToStore,
  addOneDeductionCategoryToStore,
  updateDeductionCategoryInStore,
  removeDeductionCategoryFromStore,
  addAllGroupToStore,
  addOneGroupToStore,
  updateGroupInStore,
  removeOneGroupFromStore,
  addAllLoansToStore,
  addOneLoanToStore,
  updateLoanInStore,
  removeOneLoanFromStore,
  addAll401KsToStore,
  addOne401KToStore,
  update401KInStore,
  removeOne401KFromStore,
  addAllUnitsToStore,
  addOneUnitToStore,
  updateUnitInStore,
  removeOneUnitFromStore,
  addAllGradeLevelsToStore,
  addOneGradeLevelToStore,
  updateGradeLevelInStore,
  removeOneGradeLevelFromStore,
  addAllPositionsToStore,
  addOnePositionToStore,
  updatePositionInStore,
  removeOnePositionFromStore,
  addAllPayTypesToStore,
  addOnePayTypesToStore,
  updatePayTypesInStore,
  removeOnePayTypesFromStore,
} = organizationSlice.actions;

export default organizationSlice.reducer;
