import { createSlice } from "@reduxjs/toolkit";

const employees_slice = createSlice({
  name: "employees",
  initialState: {
    employees: [],
    employee: {},
    deductions: [],
    overtimes: [],
    salaryAdvances: [],
  },
  reducers: {
    addEmployeesToStore: (state, action) => {
      state.employees = action.payload;
    },

    addOneEmployeeToStore: (state, action) => {
      state.employees.unshift(action.payload);
    },
    removeOneEmployeeFromStore: (state, action) => {
      state.employees = state.employees.filter(
        (employee) => employee.employee_id !== action.payload.employee_id
      );
    },
    addEmployeeDetailsToStore: (state, action) => {
      state.employee = action.payload;
    },
    updateEmployeeInfo: (state, action) => {
      state.employee = action.payload;
    },

    addAllEmployeeDeductionsToStore: (state, action) => {
      state.deductions = action.payload;
    },

    addAllEmployeeOvertimesToStore: (state, action) => {
      state.overtimes = action.payload;
    },

    addAllEmployeeSalaryAdvancesToStore: (state, action) => {
      state.salaryAdvances = action.payload;
    },
  },
});

export const {
  addEmployeesToStore,
  addOneEmployeeToStore,
  removeOneEmployeeFromStore,
  addEmployeeDetailsToStore,
  updateEmployeeInfo,
  addAllEmployeeDeductionsToStore,
  addAllEmployeeOvertimesToStore,
  addAllEmployeeSalaryAdvancesToStore,
} = employees_slice.actions;

export default employees_slice.reducer;
