import React from "react";
import styled from "styled-components/macro";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const YesButton = styled(Button)`
  color: #e77777;
`;
const ConfirmationDialog = ({ confirmDialog, setConfirmDialog }) => {
  const { isOpen, subTitle, title, onConfirm } = confirmDialog;
  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="confirmation-modal"
      >
        <DialogTitle fontWeight={600}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText fontWeight={500} fontSize="0.823rem">
            {subTitle}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            size="small"
            onClick={() =>
              setConfirmDialog({
                ...confirmDialog,
                isOpen: false,
              })
            }
            data-testid="cancellation"
          >
            No
          </Button>
          <YesButton
            size="small"
            onClick={onConfirm}
            autoFocus
            data-testid="confirmation"
          >
            Yes
          </YesButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
