import { createSlice } from "@reduxjs/toolkit";

const salaryad_ss_slice = createSlice({
  name: "salary_advance_ss",
  initialState: {
    salary_advances: [],
  },
  reducers: {
    add_ss_salary_advance_to_store: (state, action) => {
      state.salary_advances = action.payload;
    },
    add_one_ss_salary_advance_to_store: (state, action) => {
      state.salary_advances.unshift(action.payload);
    },
    remove_one_ss_salary_advance_from_store: (state, action) => {
      state.salary_advances = state.salary_advances.filter(
        (salary_advance) => salary_advance.id !== action.payload.id
      );
    },
    update_one_ss_salary_advance_in_store: (state, action) => {
      const claimIndex = state.salary_advances.findIndex(
        (salary_advance) => salary_advance.id === action.payload.id
      );
      state.salary_advances[claimIndex] = action.payload;
    },
  },
});

export const {
  add_ss_salary_advance_to_store,
  add_one_ss_salary_advance_to_store,
  remove_one_ss_salary_advance_from_store,
  update_one_ss_salary_advance_in_store,
} = salaryad_ss_slice.actions;

export default salaryad_ss_slice.reducer;
