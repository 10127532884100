import { createSlice } from "@reduxjs/toolkit";

const claim_type_slice = createSlice({
  name: "claim_types",
  initialState: {
    claim_types: [],
  },
  reducers: {
    add_claim_types_to_store: (state, action) => {
      state.claim_types = action.payload;
    },
    add_one_claim_type_to_store: (state, action) => {
      state.claim_types.unshift(action.payload);
    },
    remove_one_claim_type_from_store: (state, action) => {
      state.claim_types = state.claim_types.filter(
        (claim_type) => claim_type.id !== action.payload.id
      );
    },
    update_one_claim_type_in_store: (state, action) => {
      const claimTypeIndex = state.claim_types.findIndex(
        (claim_type) => claim_type.id === action.payload.id
      );

      state.claim_types[claimTypeIndex] = action.payload;
    },
  },
});

export const {
  add_claim_types_to_store,
  add_one_claim_type_to_store,
  remove_one_claim_type_from_store,
  update_one_claim_type_in_store,
} = claim_type_slice.actions;

export default claim_type_slice.reducer;
