import styled from "styled-components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";

import TableHeader from "./TableHeader";

export const StyledTableCell = styled(TableCell)`
  font-size: 0.875rem;
  font-weight: 500;
  padding: 20px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableWrapper = ({
  order,
  orderBy,
  handleRequestSort,
  headCells,
  data,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  children,
  emptyRows = 0,
}) => {
  return (
    <TableContainer
      sx={{
        mt: 6,
        borderTopLeftRadius: "1rem",
        borderTopRightRadius: "1rem",
        ...(headCells
          ? {}
          : { borderTop: "1px solid #e0e0e0", borderRadius: "0px" }),
      }}
    >
      <Table
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        {headCells && (
          <TableHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
        )}
        <TableBody>
          {children}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={3} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      {rowsPerPage ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </TableContainer>
  );
};

export default TableWrapper;
