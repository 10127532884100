import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { PrimaryButton } from "shared";

interface EmptyCategoryMessageProps {
  title: string;
  description: string;
  scrollTo: string;
}
export const EmptyCategoryList = ({
  title,
  description,
  scrollTo,
}: EmptyCategoryMessageProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <Box my={5} textAlign="center">
        <Box mb={3}>
          <Typography fontSize="1.25rem" fontWeight={700}>
            {title}
          </Typography>
          <Typography fontSize="0.9rem" maxWidth="55ch" mt={1} mb={2}>
            {description}
          </Typography>
          <PrimaryButton
            action={() =>
              navigate(`/settings/?section=${scrollTo}`, {
                state: { scrollTo: `${scrollTo}` },
              })
            }
          >
            Settings
          </PrimaryButton>
        </Box>
      </Box>
    </Box>
  );
};

export const EmptyDataState = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box my={5} textAlign="center">
        <Box mb={3}>
          <Typography fontSize="1.25rem" fontWeight={700}>
            No content found
          </Typography>
          <Typography fontSize="0.9rem">
            Click the button above to add.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
