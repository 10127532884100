import React, { SetStateAction } from "react";
import { Box, Button } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Typography14 } from "shared/Typography";
import styled from "styled-components";

export const PaginationButton = styled(Button)`
  width: fit-content;
  padding: 8px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
  min-width: 36px;
  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }

  svg {
    font-size: 18px;
  }
`;

interface PaginationProps {
  current_page: number;
  total_pages: number;
  setCurrentPage: React.Dispatch<SetStateAction<number>>;
}

const CustomPagination: React.FC<PaginationProps> = ({
  current_page,
  total_pages,
  setCurrentPage,
}) => {
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, total_pages));
  };

  return (
    <Box
      px={3}
      py={4}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <PaginationButton onClick={handlePrevPage} disabled={current_page === 1}>
        <ArrowBack />
      </PaginationButton>
      <Typography14>
        Page {current_page} of {total_pages}
      </Typography14>
      <PaginationButton
        onClick={handleNextPage}
        disabled={current_page === total_pages}
      >
        <ArrowForward />
      </PaginationButton>
    </Box>
  );
};

export default CustomPagination;
