import { createSlice } from "@reduxjs/toolkit";

const overtime_ss_slice = createSlice({
  name: "overtimes_ss",
  initialState: {
    overtimes: [],
  },
  reducers: {
    add_ss_overtimes_to_store: (state, action) => {
      state.overtimes = action.payload;
    },
    add_one_ss_overtime_to_store: (state, action) => {
      state.overtimes.unshift(action.payload);
    },
    remove_one_ss_overtime_from_store: (state, action) => {
      state.overtimes = state.overtimes.filter(
        (overtime) => overtime.id !== action.payload.id
      );
    },
    update_one_ss_overtime_in_store: (state, action) => {
      const claimIndex = state.overtimes.findIndex(
        (overtime) => overtime.id === action.payload.id
      );
      state.overtimes[claimIndex] = action.payload;
    },
  },
});

export const {
  add_ss_overtimes_to_store,
  add_one_ss_overtime_to_store,
  remove_one_ss_overtime_from_store,
  update_one_ss_overtime_in_store,
} = overtime_ss_slice.actions;

export default overtime_ss_slice.reducer;
