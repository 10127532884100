const currencySymbols = {
  USD: "$",
  NGN: "₦",
};

export type CurrencyCode = keyof typeof currencySymbols;

export const formatCurrency = (currency_code: CurrencyCode): string => {
  return currencySymbols[currency_code] || currency_code;
};

export const formatAmount = (
  figure: number,
  currency_code: CurrencyCode = "NGN",
  locale: string = "en-US"
): string => {
  if (isNaN(figure)) {
    return "";
  }

  try {
    const options = Number.isInteger(figure)
      ? undefined
      : {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        };
    const formattedFigure = new Intl.NumberFormat(locale, options).format(
      figure
    );
    const currencySymbol = formatCurrency(currency_code);
    return `${currencySymbol}${formattedFigure}`;
  } catch (error) {
    return String(figure);
  }
};
