import React from "react";
import { Box, Chip } from "@mui/material";

interface StatusColorProps {
  status: string;
}

const getStatusColor = (status: string) => {
  switch (status.toLocaleLowerCase()) {
    case "pending":
    case "processing":
      return "secondary";
    case "approved":
    case "active":
    case "processed":
      return "success";
    case "failed":
    case "declined":
    case "inactive":
      return "error";
    default:
      return "default";
  }
};

const StatusChip: React.FC<StatusColorProps> = ({ status }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Chip
        sx={{ textTransform: "capitalize" }}
        label={status}
        size="small"
        color={getStatusColor(status)}
      />
    </Box>
  );
};

export default StatusChip;
