import { createSlice } from "@reduxjs/toolkit";

const groups_slice = createSlice({
  name: "groups",
  initialState: {
    groups: [],
  },
  reducers: {
    addPayGroupsToStore: (state, action) => {
      state.groups = action.payload;
    },
    addOnePayGroupToStore: (state, action) => {
      state.groups.unshift(action.payload);
    },
    removeOnePayGroupFromStore: (state, action) => {
      state.groups = state.groups.filter(
        (group) => group.id !== action.payload.id
      );
    },
    updateOnePayGroupInStore: (state, action) => {
      const groupIndex = state.groups.findIndex(
        (group) => group.id === action.payload.id
      );
      state.groups[groupIndex] = action.payload;
    },
  },
});

export const {
  addPayGroupsToStore,
  addOnePayGroupToStore,
  removeOnePayGroupFromStore,
  updateOnePayGroupInStore,
} = groups_slice.actions;

export default groups_slice.reducer;
