import { CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components/macro";

import { useSelector } from "react-redux";
import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar";
import SidebarNavigation from "../components/sidebar";
import { NavItem, NavItemsContainer } from "../components/sidebar/containers";
import dashboardItems from "../components/sidebar/dashboard-items";
import { sessionExpiredSignal } from "../redux/slices/auth";
import SessionDialog from "./session-dialog";

const Root = styled.div`
  display: flex;
  min-height: 100dvh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: auto;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  margin-top: 70px;
  padding: 20px 40px 20px 90px;
  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 10px;
  }
  background: ${(props) => props.theme.palette.background.default};
  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 0;
  }
`;

const Dashboard = ({ children }) => {
  const session_expired = useSelector(sessionExpiredSignal);
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const location = useLocation();
  const main_content = document.getElementById("root");

  useEffect(() => {
    if (main_content) {
      main_content.scrollTo(0, 0);
    }
  }, [location, main_content]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />

      <SidebarNavigation>
        <NavItemsContainer>
          {dashboardItems.map((item, index) => {
            const { title, href, icon, children } = item;
            if (children) {
              return (
                <NavItem key={index} label={title} icon={icon} to={href}>
                  {children.map((child, index) => (
                    <NavItem
                      key={index}
                      label={child.title}
                      to={child.href}
                      icon={child.icon}
                    />
                  ))}
                </NavItem>
              );
            }
            return <NavItem key={index} to={href} label={title} icon={icon} />;
          })}
        </NavItemsContainer>
      </SidebarNavigation>
      <AppContent>
        <Navbar />

        <MainContent p={isLgUp ? 12 : 5}>
          {children}
          <Outlet />
        </MainContent>
      </AppContent>

      {session_expired && <SessionDialog />}
    </Root>
  );
};

export default Dashboard;
